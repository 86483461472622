const videos = document.querySelectorAll('[data-video]');


function handleVideo(target) {
  const { src } = target.dataset;
  // eslint-disable-next-line no-param-reassign
  target.src = src;
}


function handleVideos(payload) {
  const { target } = payload[0];
  if (payload[0].isIntersecting) {
    handleVideo(target);
    // eslint-disable-next-line no-use-before-define
    observer.unobserve(target);
  }
}


const observer = new IntersectionObserver(handleVideos);

if (videos.length > 0) {
  if ('IntersectionObserver' in window) {
    Array.from(videos).forEach((video) => {
      observer.observe(video);
    });
  } else {
    Array.from(videos).forEach((video) => {
      handleVideo(video);
    });
  }
}
