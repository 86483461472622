function collapseLists() {
  const collapseListsElements = Array.from(document.querySelectorAll('[data-toggle-text]'));

  if (window.location.hash) {
    window.addEventListener('load', () => {
      const scrollToItem = document.querySelector(`[data-href="${window.location.hash}"`);

      if(!scrollToItem) return;

      scrollToItem.classList.add('active');
      scrollToItem.nextElementSibling.classList.toggle('hidden');
      setTimeout(() => {
        window.scrollTo(0, scrollToItem.getBoundingClientRect().top + window.scrollY);
      }, 10);
    });
  }

  collapseListsElements.forEach((target) => {
    const toggleBtn = target.querySelector('[data-href]');

    toggleBtn.addEventListener('click', (event) => {
      event.preventDefault();
      toggleBtn.classList.toggle('active');
      const nextElement = toggleBtn.nextElementSibling;
      nextElement.classList.toggle('hidden');

      if (!nextElement.classList.contains('hidden')) {
        // eslint-disable-next-line no-restricted-globals
        history.replaceState(undefined, undefined, toggleBtn.dataset.href);
        document.querySelector(toggleBtn.dataset.href).scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });
  });
}


export default collapseLists();
