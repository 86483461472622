import '../css/app.css';
import '../../web/dist/symbol-sprite.svg';
import svg4everybody from 'svg4everybody';
import './modules/cookies';
import './modules/cookieBanner';
import './modules/flyout';
import './modules/flickity';
import './modules/banners';
import './modules/collapsible';
import './modules/modals';
import './modules/compare';
import './modules/beforeAfterSlider';
import serialize from 'form-serialize';
import Tabs from './modules/tabs';
import './modules/scroll-lock';
import './modules/video';
import './modules/tooltips';
import './modules/sticky_box';
import loadTracking from './modules/tracking';

// https://github.com/babel/babel/issues/9872
import 'core-js/es/array/iterator';

(() => {
  const allLocales = JSON.parse(window.allLanguageCodes);
  // No language cookie set
  if (typeof window.getCookie('craft-site-slug') === 'undefined' || window.getCookie('craft-site-slug').length === 0) {
    // Has language in url and not homepage
    if (allLocales.some(s => window.location.href.includes(`/${s}`)) && window.location.pathname.replace(/\/$/, "").split('/').length - 1 > 1) {
      window.setCookie('craft-site-slug', `${window.siteSlug}`, 30)
      window.setCookie('craft-site-handle', `${window.siteHandle}`, 30)
      return;
    }

    window.mm.show('modal-language-switcher');
    return;
  }

  // No language in url
  if(!allLocales.some(s => window.location.href.includes(`/${s}`))) {
    const otherUrls = JSON.parse(window.otherUrls);
    window.location = otherUrls[window.getCookie('craft-site-handle')];
    return;
  }

  // Homepage
  if (window.location.pathname === '/' && typeof window.getCookie('craft-site-slug') !== 'undefined' && window.getCookie('craft-site-slug').length !== 0) {
    window.location = `${window.location.origin}/${window.getCookie('craft-site-slug')}/`;
    return;
  }

  window.setCookie('craft-site-slug', `${window.siteSlug}`, 30)
  window.setCookie('craft-site-handle', `${window.siteHandle}`, 30)
})();

svg4everybody();

loadTracking();

const videoGroups = Array.from(document.querySelectorAll('#video-block'));
videoGroups.forEach((group) => {
    // eslint-disable-next-line no-new
    new Tabs(group.querySelectorAll('[data-tabs-group|="videos"]'));
});

const formElements = document.querySelectorAll('form.js-hyperform');

if (formElements.length) {
    import ( /* webpackChunkName: "forms" */ './modules/forms')
    .then((module) => {
        module.default(formElements);
    });
}

window.serialize = serialize;

const elementsToRevealOnScroll = document.querySelectorAll('.reveal-on-scroll');
if (elementsToRevealOnScroll) {
    window.onscroll = () => {
        const { scrollY } = window;
        elementsToRevealOnScroll.forEach((element) => {
            if (element.classList.contains('change-bottom')) {
                if (scrollY > 600) {
                    element.classList.remove('-bottom-28');
                    element.classList.add('bottom-0');
                } else {
                    element.classList.remove('bottom-0');
                    element.classList.add('-bottom-28');
                }
            }
        });
    };
}
