function flyout() {
  const triggers = document.querySelectorAll('.js-flyout-trigger');
  Array.from(triggers).forEach(($trigger) => {
    const $flyout = $trigger.querySelector('.js-flyout');
    $trigger.addEventListener('click', (e) => {
      e.currentTarget.classList.add('active');
      $flyout.classList.remove('hidden');
    });
    $trigger.addEventListener('mouseleave', (e) => {
      e.currentTarget.classList.remove('active');
      $flyout.classList.add('hidden');
    });
  });
}

export default flyout();
