import MicroModal from 'micromodal';

const languageInstructions = document.querySelector('[data-instructionslanguage]');
const languageSwitchLinks = Array.from(document.querySelectorAll('.language-switch__link'));
const modalTrigger = document.querySelector('[data-micromodal-trigger]');
const getInstructionsSession = sessionStorage.getItem('instructions');

function modals() {
  MicroModal.init({
    awaitCloseAnimation: true,
    onClose: ((modal) => {
      if (languageInstructions) {
        sessionStorage.setItem('instructions', '1');
      }
      if (modal.id === 'modal-language-switcher') {
        window.setCookie('craft-site-slug', `${window.siteSlug}`, 30)
        window.setCookie('craft-site-handle', `${window.siteHandle}`, 30)
      }
    }),
  });

  languageSwitchLinks.forEach((element) => {
    element.addEventListener('click', () => {
      if (element.classList.contains('language-switch__link__internal')) {
       const {slug, handle} = element.dataset;
        window.setCookie('craft-site-slug', slug, 30);
        window.setCookie('craft-site-handle', handle, 30);
      }
      if (languageInstructions) {
        sessionStorage.setItem('instructions', '1');
      }
    });
  });

  if (!getInstructionsSession && languageInstructions) {
    modalTrigger.click();
  }
}

window.mm = MicroModal;
export default modals();
