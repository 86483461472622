/* eslint-disable no-new */

import Flickity from 'flickity';
import 'flickity-as-nav-for';
import 'flickity-imagesloaded';

const sliders = document.querySelectorAll('.flickity');
if (sliders) {
  Array.from(sliders).forEach(($slider) => {
    if ($slider.children.length > 1) {
      new Flickity($slider, {
        autoPlay: false,
        prevNextButtons: true,
        pageDots: true,
        draggable: true,
        wrapAround: true,
        imagesLoaded: true,
        lazyLoad: 2,
        // setGallerySize:false,
      });
    }
  });
}

const buyCtaSliders = document.querySelectorAll('[data-buy-cta-slider]');
buyCtaSliders.forEach((slider) => {
  new Flickity(slider, {
    autoPlay: false,
    prevNextButtons: false,
    pageDots: true,
    draggable: true,
    wrapAround: true,
    imagesLoaded: true,
    lazyLoad: 2,
  });
});

const routineSliders = document.querySelectorAll('[data-routine-slider]');
const routineSlidersNav = document.querySelectorAll('[data-routine-slider-nav]');
routineSliders.forEach((slider, index) => {
  new Flickity(slider, {
    autoPlay: false,
    prevNextButtons: false,
    pageDots: false,
    draggable: false,
    wrapAround: false,
    asNavFor: routineSlidersNav[index],
  });
  new Flickity(routineSlidersNav[index], {
    autoPlay: false,
    prevNextButtons: true,
    pageDots: false,
    draggable: false,
    wrapAround: false,
  });
});
