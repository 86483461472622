class Tabs {
  constructor(tabs) {
    this.tabs = Object.values(tabs);
    this.openPanelHandlers = [];

    this.tabs.forEach(this.initializeTab);
  }

  initializeTab = (tab) => {
    tab.addEventListener('click', this.onTabClick);
  }

  onTabClick = (event) => {
    const tab = event.currentTarget;
    const panel = this.getPanelForTab(tab);

    this.closeAllPanels();
    this.openPanel(panel);
    this.setTabstoInactive();
    tab.classList.add('active');

    event.preventDefault();
  }

  setTabstoInactive() {
    this.tabs.forEach(($tab) => {
      $tab.classList.remove('active');
    });
  }

  closeAllPanels() {
    this.tabs.map(this.getPanelForTab).forEach(this.closePanel);
  }

  // eslint-disable-next-line class-methods-use-this
  getPanelForTab(tab) {
    return document.querySelector(tab.getAttribute('href'));
  }

  // eslint-disable-next-line class-methods-use-this
  closePanel(panel) {
    /* eslint-disable-next-line no-param-reassign */
    panel.style.display = 'none';
  }

  openPanel(panel) {
    const openPanelHandler = this.openPanelHandlers.find(handler => (
      panel.id === handler.panelId
    ));

    /* eslint-disable-next-line no-param-reassign */
    panel.style.display = 'block';

    if (openPanelHandler) {
      openPanelHandler.handler();
    }
  }

  addOpenPanelHandler(panelId, handler) {
    this.openPanelHandlers.push({
      panelId,
      handler,
    });
  }
}

export default Tabs;
