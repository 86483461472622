const readMoreButtons = document.querySelectorAll('[data-tracking-uid]');

function loadTracking() {
  Array.from(readMoreButtons).forEach(($button) => {
    const uid = $button.dataset.trackingUid;
    $button.addEventListener('click', () => {
      if (typeof window.productsLayer !== 'undefined') {
        window.productsLayer.forEach((item) => {
          if (item.slug === uid) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: 'productClick',
              ecommerce: {
                click: {
                  actionField: { list: item.list },
                  products: [{
                    name: item.name,
                    id: item.id,
                    // price: productObj.price,
                    brand: item.brand,
                    category: item.category,
                    position: item.position,
                  }],
                },
              },
              eventCallback() {
                document.location = $button.href;
              },
            });
          }
        });
      }
    });
  });
}

export default loadTracking;
