// Alpine js specific
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';


function toggleScrollLock(target, lock){
  const t = document.querySelector(target);
  if(lock && t){
    disableBodyScroll(t);
  } else{
    clearAllBodyScrollLocks();
  }
}

window.toggleScrollLock = toggleScrollLock;

export default toggleScrollLock;
