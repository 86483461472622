import ImageCompare from "image-compare-viewer";

const beforeAfterWrappers = document.querySelectorAll('[data-image-compare-wrapper]');
const beforeAfterThumbSliders = document.querySelectorAll('[data-before-after-slider]');

beforeAfterWrappers.forEach((element) => {
	const elementSlider = element.querySelector('[data-image-compare]');
	new ImageCompare(elementSlider, {
		controlColor: "#139A9F",
		controlShadow: false,
		addCircle: true,
		addCircleBlur: false,
	}).mount();
});

beforeAfterThumbSliders.forEach((wrapper) => {
	const elements = wrapper.querySelectorAll('div');
	elements.forEach((element, index) => {
		element.addEventListener('click', () => {
			beforeAfterWrappers.forEach(p => p.classList.add('hidden'));
			beforeAfterWrappers[index].classList.remove('hidden');
		});
	});
});
