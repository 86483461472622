let scrollY = 0;
let ticking = false;

function update() {
  ticking = false;
  if (scrollY > 400) {
    document.body.classList.add('show-stickies');
  } else {
    document.body.classList.remove('show-stickies');
  }
}

function requestTick() {
  if (!ticking) {
    requestAnimationFrame(update);
  }
  ticking = true;
}

function onScroll() {
  // eslint-disable-next-line prefer-destructuring
  scrollY = window.scrollY;
  requestTick();
}

window.addEventListener('scroll', onScroll, false);
