import { getCookie, setCookie } from './cookies';

// If Cookie banner
const cookiesBanner = document.querySelector('.js-cookie-banner');
if (cookiesBanner) {
  // Cookies
  const cookieBanner = getCookie('HIDE_COOKIE_BAR');
  // Check for empty cookies
  if (cookieBanner === '') {
    cookiesBanner.classList.add('active');
  }
  const cookieClose = document.querySelector('.js-cookie-close');
  cookieClose.addEventListener('click', (e) => {
    e.preventDefault();
    setCookie('HIDE_COOKIE_BAR', '1', 365);
    cookiesBanner.classList.remove('active');
  });
}
